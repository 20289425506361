@value primary-orange: hsla(25, 100%, 58%, 1); /*#FF8228*/
@value primary-teal: hsla(187, 100%, 33%, 1); /*#0096AA*/
@value primary-brown: hsla(57, 7%, 47%, 1); /*#81806F*/
@value primary-blue: hsla(211, 55%, 18%, 1); /*#152E48*/
@value primary-grey: hsla(57, 21%, 83%, 1); /*#DDDCCB*/
@value primary-white: hsla(0, 100%, 100%, 1); /*#FFFFFF*/

@value primary-transparent: hsla(0, 100%, 100%, 0.2);
@value primary-transparent--glass: hsla(0, 100%, 100%, 0.05);

@value primary-white-light: hsla(0, 100%, 100%, 0.6);
@value primary-white-lighter: hsla(0, 100%, 100%, 0.4);

@value primary-brown-transparent: hsla(57, 7%, 47%, 0.1); /* #81806F 10% */
@value secondary-brown-transparent: hsla(57, 7%, 47%, 0.15); /* #81806F 15% */

@value secondary-black: hsla(0, 0%, 0%, 0.8);
/* looks like  #333 wich is hsla(0, 0%, 20%, 1) on a white background*/
@value secondary-black--light: hsla(0, 0%, 0%, 0.6);
@value secondary-black--lighter: hsla(0, 0%, 0%, 0.4);
@value secondary-black--very-light: hsla(0, 0%, 0%, 0.2);

@value secondary-green: hsla(149, 100%, 33%, 1);
/* hsla(140, 60%, 42%, 1) is currently in use in NE */ /*#00A651*/

@value secondary-blue: hsla(212, 50%, 41%, 1); /*#35669E*/
@value secondary-blue--lighter: hsla(212, 50%, 51%, 1);
@value secondary-blue--darker: hsla(212, 50%, 31%, 1);
@value secondary-blue--semi-transparent: hsla(212, 50%, 41%, 0.2);
@value secondary-blue--transparent: hsla(212, 50%, 41%, 0.1);
@value secondary-blue--very-transparent: hsla(212, 50%, 41%, 0.05);
@value solid-secondary-blue--very-transparent-on-white: #f5f7fa;

@value secondary-blue-button--border: hsla(212, 50%, 41%, 0.6);

@value secondary-orange: hsla(35, 99%, 57%, 1); /*#FEA425*/
@value secondary-red: hsla(358, 85%, 52%, 1); /*#EC1C24*/

@value secondary-brown: hsla(28, 51%, 40%, 1); /*#996231*/
@value secondary-grey: hsla(211, 12%, 49%, 1);

@value secondary-grey--very-light: hsla(0, 0%, 94%, 1);
@value online-bank-light-grey: hsla(0, 0%, 0%, 0.07);

@value switch-blue: hsla(200, 95%, 58%, 1); /* #30B6FA */
@value switch-blue--lighter: hsla(200, 95%, 58%, 0.6); /* #30B6FA */
@value switch-blue--light: hsla(200, 95%, 58%, 0.2); /* #30B6FA */

@value switch-blue10: hsla(200, 95%, 58%, .1); /* #30B6FA  10% opacity */
@value switchBlue: hsla(200, 95%, 58%, 1); /* #30B6FA */

@value switch-blue--very-light: hsla(200, 95%, 58%, 0.2);
@value text-on-grey: hsla(218, 10%, 31%, 1);
@value deep-blue: #1E3C5A;

@value grey-very-light: hsla(210, 3%, 87%, 1);

@value login-background-grey: hsla(202, 3%, 95%, 0.94);

@value light-blue-icon-background: hsla(200, 74%, 60%, 1);

@value ligth-grey-blue: hsla(211, 55%, 18%, 0.8);

@value input-border-color: hsla(200, 95%, 58%, 0.2);
@value input-border-color-focus: hsla(200, 95%, 58%, 1);

.color-primary-orange {
  background-color: primary-orange;
  color: primary-white;
}

.color-primary-teal {
  background-color: primary-teal;
  color: primary-white;
}

.color-primary-brown {
  background-color: primary-brown;
  color: primary-white;
}

.color-primary-blue {
  background-color: primary-blue;
  color: primary-white;
}

.color-primary-grey {
  background-color: primary-grey;
  color: secondary-black;
}

.color-primary-white {
  background-color: primary-white;
  color: secondary-black;
}

.color-switch-blue {
  background-color: switch-blue;
  color: primary-white;
}

.color-secondary-black {
  background-color: secondary-black;
  color: primary-white;
}
