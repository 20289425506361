body,
html {
  height: 100vh;
  padding: 0;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  overflow: hidden;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* removes scrollbar view in web browsers */
::-webkit-scrollbar {
  display: none;
}
* {
  scrollbar-width: none;
}
